import React from "react"
import { getImage } from "gatsby-plugin-image"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import { Profile } from "../styles/Assets.styled"

export default function Contact({ data }) {
  const image = data.contentfulPerson.image
  // const email = data.contentfulPerson.email
  return (
    <Layout title="Contact">
      <div>
        <p>You wanna contact this guy?</p>
        <Profile alt="memoji" image={getImage(image)} width={128} />
      </div>
      <div>
        <p>Say hi!</p>
        {/*<form name="contact" method="POST" data-netlify="true" action="/">*/}
        {/*<form name="contact" method="POST">*/}
        <form method="post" data-netlify="true" netlify-honeypot="bot-field" name="contact" action="/">
        {/*  <input type="hidden" name="bot-field" />*/}
          <input type="hidden" name="form-name" value="contact" />
          <p>
            <label>
              Name:<br/>
              <input type="text" name="name" />
            </label>
          </p>
          <p>
            <label>
              Email:<br/>
              <input type="email" name="email" />
            </label>
          </p>
          <p>
            <label>
              Message:<br/>
              <textarea name="message" />
            </label>
          </p>
          <p>
            <button type="submit" name="submit">Contact!</button>
          </p>
        </form>
      </div>
    </Layout>
  )
}

export const memoji = graphql`
  query AuthorContact {
    contentfulPerson(name: { glob: "Mike*" }) {
      name
      email
      id
      image {
        gatsbyImageData(
          placeholder: TRACED_SVG
          layout: CONSTRAINED
          width: 240
        )
      }
    }
  }
`
